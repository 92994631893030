import React, {useEffect, useState} from 'react'
import GraphQLErrorList from '../components/graphql-error-list'

import Container from '../components/container'
import Seo from '../components/seo'
import Heading from '../components/heading'
import Layout from '../containers/layout'
import PaymentCheck from '../components/payment-check'

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout isHome>
      <Seo />
      <Container>
        <Heading ja='申込完了・チェックでのお支払いについて' en='' />
        <PaymentCheck />
      </Container>
    </Layout>
  )
}

export default IndexPage
