import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'

const StyledBasePortableText = styled.div`
  padding: 2rem 0 ;
  max-width: 640px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.8rem;
`

const Small = styled.small`
  font-size: 1.2rem;
`

const Heading = props => {
  const {title, color, stripe} = props
  return (
    <>
      <StyledBasePortableText>
        <h2>登録内容をご確認ください</h2>
        <p>この度はお申込いただきありがとうございます。<br />
        確認メールを送信しております。入力内容に間違いがないか必ずご確認ください。<br />
          <Small>3分以上待ってもメールが届かない場合は当学園までお問い合わせください。</Small>
        </p>
        <p>チェックは下記の住所までx日以内にご郵送ください。</p>

        <h2>チェックの書き方について</h2>
        <p><b>Pay to the Order of(宛名)</b><br /> Japanese Childrens Society, Inc.</p>
        <p><b>Memo(メモ)</b><br />  メールに記載されている受付番号をご記入ください。(例: 20A-000000)<br /><Small>Memo欄に書ききれない場合は別の用紙にご記入の上、御同封ください。</Small></p>

        <h2>チェックの郵送方法について</h2>
        <p>チェックは下記の住所までx日以内にご郵送ください。</p>
        <p><b>宛先</b><br />Japanese Childrens Society, Inc.</p>
        <p><b>住所</b><br />8 West Bayview Avenue,<br /> Englewood Cliffs, NJ 07632</p>

        <h2>アンケートのお願い</h2>
        <p>今後のウェブ決済実施のためアンケートのご協力をお願いしています。<br />数分で完了する匿名アンケートとなっておりますのでご協力ください</p>
        <iframe className='airtable-embed' src='https://airtable.com/embed/shro3IZk4lA95bMzO?backgroundColor=yellow' frameBorder='0' onmousewheel='' width='100%' height='2000' />
      </StyledBasePortableText>
    </>
  )
}
export default Heading
